import * as React from "react"
import { graphql } from 'gatsby'

import Seo from "../components/seo"
import AnimationWrapper from "../components/animationWrapper"
import ContactSection from "../components/contactSection"
import Container from "../components/container"
import Header from "../components/header"
import IconSection from "../components/iconSection"
import InnerSection from "../components/innerSection"
import ProductTilesSection from "../components/productTilesSection"
import DownloadSection from '../components/downloadSection'
import TextSection from "../components/textSection"
import Layout from "../components/layout"

// import Keramzyt1 from '../images/keramzyt/keramzyt-1.jpg'
import Keramzyt2 from '../images/keramzyt/keramzyt-2.jpg'
import Keramzyt3 from '../images/keramzyt/keramzyt-3.jpg'
import KeramzytCircle from '../images/keramzyt/keramzyt-circle.jpg'

import "./keramzyt.css"

const KeramzytPage = ({data, location}) => {

  // set meta description values
  const metaDescription = data.page.frontmatter.meta_description || `Czamaninek ➤ ${data.page.frontmatter.title} ✔️ Ekologiczne materiały budowlane ✔️ Polski producent ⭐ Sprawdź!`;

  return (
    <Layout location={location}>

      <Seo title={data.page.frontmatter.title} description={metaDescription} />

      <AnimationWrapper>
        <Header data={data.page.frontmatter.header} slug={data.page.frontmatter.slug} />
      </AnimationWrapper>

      <Container>
        <TextSection className="keramzyt-description description-medium" data={data.page.frontmatter.text_sections[0]} />
      </Container>

      <InnerSection>
        <div className="image-container">
          <div className="content-container">
            <img src={Keramzyt3} alt="Czamaninek" />
          </div>
        </div>
        <div className="description-container">
            <TextSection className="description-medium align-left" data={data.page.frontmatter.text_sections[1]} />
        </div>
      </InnerSection>

      <section id="keramzyt-tiles-section" className="product-tiles-section">
        <ProductTilesSection data={data.keramzytProducts} subcategoryName="Dostępne frakcje keramzytu" location="keramzyt" />
      </section>

      {data.download.nodes[0] &&
        <DownloadSection data={data.download} location="Do pobrania" />
      }

      <Container>
        <TextSection className="description-medium" data={data.page.frontmatter.text_sections[2]} />
      </Container>

      <InnerSection className="keramzyt-description-4">
        <div className="description-container">
            <TextSection className="description-medium align-right" data={data.page.frontmatter.text_sections[3]} />
        </div>
        <div className="image-container">
          <div className="content-container">
            <img src={KeramzytCircle} alt="Czamaninek" />
          </div>
        </div>
      </InnerSection>

      <section id="keramzyt-icon-section" className="page-section">
          <AnimationWrapper animationType="fadeIn">
            <IconSection data={data.page.frontmatter} image={data.iconsKeramzytImg} location="keramzyt" />
          </AnimationWrapper>
      </section>

      <Container>
        <TextSection className="description-medium" data={data.page.frontmatter.text_sections[4]} />
      </Container>

      <InnerSection>
        <div className="image-container">
          <div className="content-container">
            <img src={Keramzyt2} alt="Czamaninek" />
          </div>
        </div>
        <div className="description-container">
            <TextSection className="description-medium align-left" data={data.page.frontmatter.text_sections[5]} />
        </div>
      </InnerSection>

      <section id="contact-data" className="page-section">
        <ContactSection data={data.contactSection.frontmatter.contact_section} />
      </section>

    </Layout>
  )
}

export const query = graphql`
  query{
    page: mdx(frontmatter: { slug: { eq: "keramzyt" }, type: { eq: "page" } }) {
      frontmatter{
        title
        meta_description
        slug
        header{
          title
          breadcrumbs {
            title
            url
          }
          overlay
          image {
            childImageSharp {
              gatsbyImageData(
                transformOptions: {fit: COVER},
                webpOptions: {quality: 95}
              )
            }
          }
        }
        text_sections{
          title
          title_visible
          icon{
            childImageSharp {
              gatsbyImageData(
                transformOptions: {fit: COVER},
                webpOptions: {quality: 95}
              )
            }
          }
          text
        }
        icons_section_title
        icons{
          title
          text
          image{
            childImageSharp {
              gatsbyImageData(
                transformOptions: {fit: COVER},
                webpOptions: {quality: 95}
              )
            }
          }
        }
      }
    }
    iconsKeramzytImg: file(
      relativePath: {eq: "keramzyt/keramzyt-1.jpg"}
    ){
      id
      publicURL
      childImageSharp {
        gatsbyImageData(
          transformOptions: {fit: COVER},
          webpOptions: {quality: 95}
        )
      }
      relativePath
    }
    keramzytProducts: allMdx(
      sort: { fields: [frontmatter___order], order: ASC }
      filter: {
        internal: {contentFilePath: {regex: "/(products)/"}},
        frontmatter: {category: {eq: "keramzyt"}}
      }
    ){
      nodes {
        frontmatter {
          name
          slug
          subtitle
          desc_short
          image {
            childImageSharp {
              gatsbyImageData(
                transformOptions: {fit:COVER}
                webpOptions: {quality:95}
              )
            }
          }
          image_second {
            childImageSharp {
              gatsbyImageData(
                transformOptions: {fit:COVER}
                webpOptions: {quality:95}
              )
            }
          }
        }
        id
        body
      }
    }
    download: allMdx(
      sort: {fields: [frontmatter___order], order: ASC}
      filter: {
        frontmatter: {
          connections: {eq: "keramzyt" },
          type: {eq: "download"}
        }
      }
    ) {
      nodes {
        frontmatter {
          name
          file {
            publicURL
          }
        }
        id
      }
    }
    contactSection: mdx(
      frontmatter: {slug: {eq: "contact-section"}, type: {eq: "global"}}
    ){
      frontmatter{
        contact_section{
          title
          description
          type
          fields
          submit_button_style
          image{
            childImageSharp{
              gatsbyImageData(
                webpOptions: {quality: 95}
              )
            }
          }
          image_object_fit
          image_object_position
        }
      }
    }
  }
`

export default KeramzytPage
